<template>
  <div class="animated fadeIn">
    <b-card>
      <h4><i class="fa fa-chart-line"></i> Invoice Chart</h4>
      <Chart />
    </b-card>
  </div>
</template>

<script>
import Chart from './charts/Chart.vue'

export default {
  name: 'WtsCharts',
  components: {
    Chart,
  },
  data () {
    return {

    }
  },
}
</script>

<style lang="scss">


</style>
