var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app" }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-6 col-xl-6 mt-3" },
        [
          _c("h5", [
            _vm._v("Select Project "),
            _c("i", {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true }
                }
              ],
              staticClass: "ml-2 fa fa fa-question-circle",
              attrs: { title: "You may select multiple projects" }
            })
          ]),
          _c("multiselect", {
            attrs: {
              placeholder: "Search or add project",
              "tag-placeholder": "Search or add project",
              "track-by": "projectid",
              options: _vm.projectData,
              multiple: true,
              "close-on-select": false,
              "clear-on-select": false,
              "preserve-search": true,
              taggable: true,
              label: "projectname",
              "preselect-first": true
            },
            on: {
              input: function($event) {
                return _vm.getAfes()
              }
            },
            model: {
              value: _vm.projectid,
              callback: function($$v) {
                _vm.projectid = $$v
              },
              expression: "projectid"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6 col-xl-6 mt-3" },
        [
          _c("h5", [_vm._v("Select AFE / PO")]),
          _c("multiselect", {
            attrs: {
              placeholder: "Search or add afe",
              "tag-placeholder": "Search or add afe",
              "track-by": "pwcaid",
              options: _vm.afeData,
              multiple: true,
              "close-on-select": false,
              "clear-on-select": false,
              "preserve-search": true,
              taggable: true,
              label: "afenum",
              "preselect-first": true
            },
            model: {
              value: _vm.afes,
              callback: function($$v) {
                _vm.afes = $$v
              },
              expression: "afes"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-9 col-xl-9 mt-3" },
        [
          _c("h5", [_vm._v("Select UWI")]),
          _c("multiselect", {
            attrs: {
              placeholder: "Search or add uwi",
              "tag-placeholder": "Search or add uwi",
              "track-by": "pwid",
              options: _vm.uwiData,
              multiple: true,
              "close-on-select": false,
              "clear-on-select": false,
              "preserve-search": true,
              taggable: true,
              label: "uwi",
              "preselect-first": true
            },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function(props) {
                  return [
                    _c("div", { staticClass: "option__desc" }, [
                      _c(
                        "div",
                        {
                          staticClass: "option__title rt-uwi-slot-multiselect"
                        },
                        [
                          props.option.uwi_3
                            ? _c("span", [
                                _vm._v(
                                  "(" + _vm._s(props.option.uwi_3) + ") - "
                                )
                              ])
                            : _vm._e(),
                          props.option.uwi_2
                            ? _c("span", [
                                _vm._v(
                                  "(" + _vm._s(props.option.uwi_2) + ") - "
                                )
                              ])
                            : _vm._e(),
                          props.option.uwi
                            ? _c("span", [
                                _vm._v("(" + _vm._s(props.option.uwi) + ")")
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.uwis,
              callback: function($$v) {
                _vm.uwis = $$v
              },
              expression: "uwis"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3 col-xl-3 mt-3" },
        [
          _c("h5", [_vm._v("Build")]),
          _c(
            "b-button",
            {
              staticClass: "w-100 mt-1",
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  return _vm.loadInvoices()
                }
              }
            },
            [
              _vm._v("\n        Build "),
              _c("i", { staticClass: "fa fa-chart-bar" })
            ]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { attrs: { id: "invoice-chart" } },
      [
        _c("rotate-square2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { id: "loading-gif" }
        }),
        _c("div", {
          staticStyle: { width: "100%", height: "640px" },
          attrs: { id: "chartdiv" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }