<template>
  <div class="app">
    <div class="row mb-4">
      <div class="col-md-6 col-xl-6 mt-3">
        <h5>Select Project <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="You may select multiple projects"></i></h5>
        <multiselect @input="getAfes()" v-model="projectid" placeholder="Search or add project" tag-placeholder="Search or add project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :taggable="true" label="projectname" :preselect-first="true">
        </multiselect>
      </div>
      <div class="col-md-6 col-xl-6 mt-3">
        <h5>Select AFE / PO</h5>
        <multiselect v-model="afes" placeholder="Search or add afe" tag-placeholder="Search or add afe" track-by="pwcaid" :options="afeData" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :taggable="true" label="afenum" :preselect-first="true">
        </multiselect>
      </div>
      <div class="col-md-9 col-xl-9 mt-3">
        <h5>Select UWI</h5>
        <multiselect v-model="uwis" placeholder="Search or add uwi" tag-placeholder="Search or add uwi" track-by="pwid" :options="uwiData" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :taggable="true" label="uwi" :preselect-first="true">
          <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title rt-uwi-slot-multiselect">
                  <span v-if="props.option.uwi_3">({{props.option.uwi_3}}) - </span><span v-if="props.option.uwi_2">({{props.option.uwi_2}}) - </span><span v-if="props.option.uwi">({{props.option.uwi}})</span>
                </div>
              </div>
            </template>
        </multiselect>
      </div>
      <div class="col-md-3 col-xl-3 mt-3">
        <h5>Build</h5>
        <b-button class="w-100 mt-1" variant="success" @click="loadInvoices()">
          Build <i class="fa fa-chart-bar"></i>
        </b-button>
      </div>
    </div>

    <div id="invoice-chart">
      <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
      <div id="chartdiv" style="width: 100%; height: 640px;"></div>
    </div>

  </div>
</template>
<script src="//www.amcharts.com/lib/4/plugins/responsive/responsive.min.js"></script>
<script>

import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

export default {
  name: 'Chart',
  components: {
    Multiselect,
    RotateSquare2,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      afeData: [],
      afes: [],
      uwiData: [],
      uwis: [],
      pendingWSS: [],
      pendingWTS: [],
      pendingMng: [],
      pendingPayment: [],
      paid: [],
      startDate: '',
      loading: false,
      companyid: 0
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    getAfes() {
      this.afes = [];
      this.uwis = [];
      this.afeData = [];
      this.uwiData = [];

      for (var i = 0; i < this.projectid.length; i++) {
        if(this.$session.get('user') == 'pm'){
          this.$axios.get('/wts/get/afes/' + this.projectid[i].projectid).then(response => {
            this.afeData = this.lodash.uniqBy(this.afeData.concat(response.data.result), 'pwcaid')
            this.uwiData = this.lodash.uniqBy(this.uwiData.concat(response.data.result), 'uwi')
          })
        }
        else {
          this.$axios.get('/manager/get/wts_afe/' + this.projectid[i].projectid + '/' + this.companyid).then(response => {
            this.afeData = this.lodash.uniqBy(this.afeData.concat(response.data.result), 'pwcaid');
            this.uwiData = this.lodash.uniqBy(this.uwiData.concat(response.data.result), 'uwi')
          })
        }
      }
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    getAfeArray(){
      var temp = this.afes;
      return temp
    },
    getAllChartData(data, projectids, afes, uwis) {
      const res = new Promise((resolve, reject) => {
        const promises = data.map(record => this.$http.post('/charts/v2/4', {
          projectids: projectids,
          companyid: this.companyid,
          pwcaid: afes,
          uwis: uwis,
          start_date: this.$moment(record).startOf('month').format('YYYY-MM-DD'),
          end_date: this.$moment(record).endOf('month').format('YYYY-MM-DD')
        }));
        //console.log(promises)

        Promise.all(promises) // creates single promise that resolves when all `promises` resolve
          .then(responses => {
            const dataArray = responses.map(response => response.data);
            return resolve(dataArray);
          }) // resolves with an array of response data
          .catch(reject);
      })
      return res;
    },
    async loadInvoices(a){
      var temp = this.projectid.sort(function(a,b) {
        return new Date(a.p_start_date).getTime() - new Date(b.p_start_date).getTime()
      })[0];

      this.startDate = temp.p_start_date;

      if(this.projectid.length === 0){
        this.$toasted.show('Error. Please select project.', {type: 'error', duration: '3000'})
        return false;
      }
      if(this.afes.length === 0 && this.uwis.length === 0){
        this.$toasted.show('Error. Please select uwi or afe.', {type: 'error', duration: '3000'})
        return false;
      }
      this.loading = true;

      var dateStart = this.$moment(this.startDate)
      var dateEnd = this.$moment(new Date).add(1, 'days')
      var timeValues = [];

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        timeValues.push(dateStart.format('YYYY-MM-DD'));
        dateStart.add(1,'month');
      }
      var projectids = [];
      var afes = [];
      var uwis = [];
      var afes = this.afes.map(x=>x.pwcaid);
      var uwis = this.uwis.map(x=>x.uwi)
      var projectids = this.projectid.map(x=>x.projectid);
      var apiData = [];

      const getChartData = await this.getAllChartData(timeValues, projectids, afes, uwis)
      .then(data => {
        const that = this;

        return that.lodash.map(data, function(item){
          item.month = that.$moment(item.start_date).format('YYYY-MM');
          if(item.afe_data.wss_pending > 0){
            item.afe_wss_pending = item.afe_data.wss_pending
          }
          if(item.afe_data.wts_pending > 0){
            item.afe_wts_pending = item.afe_data.wts_pending
          }
          if(item.afe_data.manager_pending > 0){
            item.afe_manager_pending = item.afe_data.manager_pending
          }
          if(item.afe_data.accountant_pending > 0){
            item.afe_accountant_pending = item.afe_data.accountant_pending
          }
          if(item.afe_data.paid > 0){
            item.afe_paid = item.afe_data.paid
          }
          if(item.uwi_data.wss_pending > 0){
            item.uwi_wss_pending = item.uwi_data.wss_pending
          }
          if(item.uwi_data.wts_pending > 0){
            item.uwi_wts_pending = item.uwi_data.wts_pending
          }
          if(item.uwi_data.manager_pending > 0){
            item.uwi_manager_pending = item.uwi_data.manager_pending
          }
          if(item.uwi_data.accountant_pending > 0){
            item.uwi_accountant_pending = item.uwi_data.accountant_pending
          }
          if(item.uwi_data.paid > 0){
            item.uwi_paid = item.uwi_data.paid
          }

          return item;
        });
      }).catch(error => {
        console.log(error)
      })

      console.log(getChartData);

      var chartDataWithQuarters = [];

      for (var i = 0; i < getChartData.length; i++) {
        let m = this.$moment(getChartData[i].month).format("M");
        let data = getChartData[i];

        chartDataWithQuarters.push(data)

        if(m % 3 == 0 && i + 1 <= getChartData.length){
          //console.log('q_push')
          chartDataWithQuarters.push(data)

          //console.log(this.$moment(getChartData[i].month).startOf('quarter'))

          var findMonthsInQuarter = getChartData.filter(
            x => this.$moment(getChartData[i].month).isSameOrAfter(this.$moment(x.month).startOf('quarter'), 'month')
            && this.$moment(getChartData[i].month).isSameOrBefore(this.$moment(x.month).endOf('quarter'), 'month'))

          console.log(findMonthsInQuarter, 'qtr', getChartData[i].month);

          var uwi_wss_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.wss_pending
          }, 0);
          var uwi_wts_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.wts_pending
          }, 0);
          var uwi_manager_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.manager_pending
          }, 0);
          var uwi_accountant_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.accountant_pending
          }, 0);
          var uwi_paid = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.paid
          }, 0);
          var afe_wss_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.wss_pending
          }, 0);
          var afe_wts_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.wts_pending
          }, 0);
          var afe_manager_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.manager_pending
          }, 0);
          var afe_accountant_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.accountant_pending
          }, 0);
          var afe_paid = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.paid
          }, 0);

          //console.log(uwi_wts_pending)

          var chartDataTemp = {
            "month": "Q" + this.$moment(getChartData[i].month).quarter() + "-" + this.$moment(getChartData[i].month).year(),
          }

          if(uwi_wss_pending > 0){
            chartDataTemp.uwi_wss_pending = uwi_wss_pending;
          }

          if(uwi_wts_pending > 0){
            chartDataTemp.uwi_wts_pending = uwi_wts_pending;
          }

          if(uwi_manager_pending > 0){
            chartDataTemp.uwi_manager_pending = uwi_manager_pending;
          }

          if(uwi_accountant_pending > 0){
            chartDataTemp.uwi_accountant_pending = uwi_accountant_pending;
          }

          if(uwi_paid > 0){
            chartDataTemp.uwi_paid = uwi_paid;
          }

          if(afe_wss_pending > 0){
            chartDataTemp.afe_wss_pending = afe_wss_pending;
          }

          if(afe_wts_pending > 0){
            chartDataTemp.afe_wts_pending = afe_wts_pending;
          }

          if(afe_manager_pending > 0){
            chartDataTemp.afe_manager_pending = afe_manager_pending;
          }

          if(afe_accountant_pending > 0){
            chartDataTemp.afe_accountant_pending = afe_accountant_pending;
          }

          if(afe_paid > 0){
            chartDataTemp.afe_paid = afe_paid;
          }

          chartDataWithQuarters.push(chartDataTemp)
        }
        if(m % 3 != 0 && i + 1 == getChartData.length){
          chartDataWithQuarters.push(data)

          var findMonthsInQuarter = getChartData.filter(
            x => this.$moment(getChartData[i].month).isSameOrAfter(this.$moment(x.month).startOf('quarter'), 'month')
            && this.$moment(getChartData[i].month).isSameOrBefore(this.$moment(x.month).endOf('quarter'), 'month'))
          console.log(findMonthsInQuarter, 'last month qtr', getChartData[i].month)

          var uwi_wss_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.wss_pending
          }, 0);
          var uwi_wts_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.wts_pending
          }, 0);
          var uwi_manager_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.manager_pending
          }, 0);
          var uwi_accountant_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.accountant_pending
          }, 0);
          var uwi_paid = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.paid
          }, 0);
          var afe_wss_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.wss_pending
          }, 0);
          var afe_wts_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.wts_pending
          }, 0);
          var afe_manager_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.manager_pending
          }, 0);
          var afe_accountant_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.accountant_pending
          }, 0);
          var afe_paid = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.paid
          }, 0);

          var chartDataTemp = {
            "month": "Q" + this.$moment(getChartData[i].month).quarter() + "-" + this.$moment(getChartData[i].month).year()
          }

          console.log(chartDataTemp)

          if(uwi_wss_pending > 0){
            chartDataTemp.uwi_wss_pending = uwi_wss_pending;
          }

          if(uwi_wts_pending > 0){
            //console.log(uwi_wts_pending, '0')
            chartDataTemp.uwi_wts_pending = uwi_wts_pending;
            //console.log(chartDataTemp.uwi_wts_pending, '9')
          }

          if(uwi_manager_pending > 0){
            chartDataTemp.uwi_manager_pending = uwi_manager_pending;
          }

          if(uwi_accountant_pending > 0){
            chartDataTemp.uwi_accountant_pending = uwi_accountant_pending;
          }

          if(uwi_paid > 0){
            chartDataTemp.uwi_paid = uwi_paid;
          }

          if(afe_wss_pending > 0){
            chartDataTemp.afe_wss_pending = afe_wss_pending;
          }

          if(afe_wts_pending > 0){
            chartDataTemp.afe_wts_pending = afe_wts_pending;
          }

          if(afe_manager_pending > 0){
            chartDataTemp.afe_manager_pending = afe_manager_pending;
          }

          if(afe_accountant_pending > 0){
            chartDataTemp.afe_accountant_pending = afe_accountant_pending;
          }

          if(afe_paid > 0){
            chartDataTemp.afe_paid = afe_paid;
          }

          chartDataWithQuarters.push(chartDataTemp)
        }
        if(m == 12 || i + 1 == getChartData.length){
          chartDataWithQuarters.push(data)

          var findMonthsInQuarter = getChartData.filter(
            x => this.$moment(getChartData[i].month).isSameOrAfter(this.$moment(x.month).startOf('year'), 'month')
            && this.$moment(getChartData[i].month).isSameOrBefore(this.$moment(x.month).endOf('year'), 'month'))

          console.log(findMonthsInQuarter, 'year');

          var uwi_wss_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.wss_pending
          }, 0);
          var uwi_wts_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.wts_pending
          }, 0);
          var uwi_manager_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.manager_pending
          }, 0);
          var uwi_accountant_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.accountant_pending
          }, 0);
          var uwi_paid = findMonthsInQuarter.reduce(function(total, item){
            return total + item.uwi_data.paid
          }, 0);
          var afe_wss_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.wss_pending
          }, 0);
          var afe_wts_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.wts_pending
          }, 0);
          var afe_manager_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.manager_pending
          }, 0);
          var afe_accountant_pending = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.accountant_pending
          }, 0);
          var afe_paid = findMonthsInQuarter.reduce(function(total, item){
            return total + item.afe_data.paid
          }, 0);

          var chartDataTemp = {
            "month": "YTD-" + this.$moment(getChartData[i].month).year()
          }

          if(uwi_wss_pending > 0){
            chartDataTemp.uwi_wss_pending = uwi_wss_pending;
          }

          if(uwi_wts_pending > 0){
            chartDataTemp.uwi_wts_pending = uwi_wts_pending;
          }

          if(uwi_manager_pending > 0){
            chartDataTemp.uwi_manager_pending = uwi_manager_pending;
          }

          if(uwi_accountant_pending > 0){
            chartDataTemp.uwi_accountant_pending = uwi_accountant_pending;
          }

          if(uwi_paid > 0){
            chartDataTemp.uwi_paid = uwi_paid;
          }

          if(afe_wss_pending > 0){
            chartDataTemp.afe_wss_pending = afe_wss_pending;
          }

          if(afe_wts_pending > 0){
            chartDataTemp.afe_wts_pending = afe_wts_pending;
          }

          if(afe_manager_pending > 0){
            chartDataTemp.afe_manager_pending = afe_manager_pending;
          }

          if(afe_accountant_pending > 0){
            chartDataTemp.afe_accountant_pending = afe_accountant_pending;
          }

          if(afe_paid > 0){
            chartDataTemp.afe_paid = afe_paid;
          }

          chartDataWithQuarters.push(chartDataTemp)

        }


        // console.log(i, 'index')
        // console.log(m, 'month')
        // console.log(m % 3, 'remainder')
        // console.log(getChartData.length, '# of months')
      }

      //console.log(chartDataWithQuarters, 'qs')

      const createChart = await this.createChart(chartDataWithQuarters)
    },
    createChart(apiData) {
      var chart = am4core.create("chartdiv", am4charts.XYChart);
      //console.log(apiData)

      chart.colors.list = [
        am4core.color("#F44336"),
        am4core.color("#E91E63"),
        am4core.color("#9C27B0"),
        am4core.color("#673AB7"),
        am4core.color("#3F51B5"),
        am4core.color("#2196F3"),
        am4core.color("#03A9F4"),
        am4core.color("#00BCD4"),
        am4core.color("#009688"),
        am4core.color("#4CAF50"),
        am4core.color("#8BC34A"),
        am4core.color("#CDDC39"),
        am4core.color("#FFEB3B"),
        am4core.color("#FFC107"),
        am4core.color("#FF9800"),
        am4core.color("#FF5722"),
        am4core.color("#795548"),
        am4core.color("#9E9E9E"),
        am4core.color("#607D8B")
      ];

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;

      categoryAxis.renderer.labels.template.rotation = 320;
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.title.text = "Invoices";
      valueAxis.calculateTotals = true;
      valueAxis.maxPrecision = 0;

      // Create series
      function createSeries(field, name, stacked, last) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "month";
        series.name = name;
        //series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(50);

        series.bullets.push(new am4charts.LabelBullet());

        if(last){
          var bullet = series.bullets.push(new am4charts.LabelBullet());
          bullet.label.text = "{valueY}";
          bullet.locationY = 0.5;
          bullet.align = "center";
          bullet.valign = "middle";
          bullet.label.fontSize = 14;
        }
        if(!last){
          var bullet = series.bullets.push(new am4charts.LabelBullet());
          bullet.label.text = "{valueY}";
          bullet.locationY = 0.5;
          bullet.align = "center";
          bullet.valign = "middle";
          bullet.label.fontSize = 14;
        }

        chart.maskBullets = false;
      }
      chart.scrollbarX = new am4core.Scrollbar();
      chart.cursor = new am4charts.XYCursor();

      createSeries("afe_wss_pending", "AFE WSS Pending", true);
      createSeries("afe_pm_pending", "AFE PM Pending", true);
      createSeries("afe_manager_pending", "AFE Manager Pending", true);
      createSeries("afe_accountant_pending", "AFE Accountant Pending", true);
      createSeries("afe_paid", "AFE Paid", true, true);

      createSeries("uwi_wss_pending", "UWI WSS Pending", false);
      createSeries("uwi_pm_pending", "UWI PM Pending", true);
      createSeries("uwi_manager_pending", "UWI Manager Pending", true);
      createSeries("uwi_accountant_pending", "UWI Accountant Pending", true);
      createSeries("uwi_paid", "UWI Paid", true, true);


      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.margin('10', '0', '0', '0')
      chart.legend.position = "bottom";

      chart.legend.useDefaultMarker = true;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");
      //chart.legend.markers.template.disabled = true;

      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = "Invoice Chart Export";
      chart.exporting.menu.items = [{
        "label": "Export <i class='fa fa-export'></i>",
        "menu": [
          {
            "label": "Image",
            "menu": [
              { "type": "png", "label": "PNG" },
              { "type": "jpg", "label": "JPG" },
              { "type": "gif", "label": "GIF" },
              { "type": "svg", "label": "SVG" },
            ]
          }, {
            "label": "Data",
            "menu": [
              { "type": "json", "label": "JSON" },
              { "type": "csv", "label": "CSV" },
              { "type": "xlsx", "label": "XLSX" }
            ]
          }, {
            "label": "Print", "type": "print"
          }
        ]
      }];

      chart.data = apiData.map(item => ({
        month: item.month,
        afe_wss_pending: item.afe_wss_pending,
        afe_pm_pending: item.afe_wts_pending,
        afe_manager_pending: item.afe_manager_pending,
        afe_accountant_pending: item.afe_accountant_pending,
        afe_paid: item.afe_paid,
        uwi_wss_pending: item.uwi_wss_pending,
        uwi_pm_pending: item.uwi_wts_pending,
        uwi_manager_pending: item.uwi_manager_pending,
        uwi_accountant_pending: item.uwi_accountant_pending,
        uwi_paid: item.uwi_paid,
      }));
      console.log(chart.data);
      this.loading = false;
    }
  },
  mounted() {
    if(this.$session.get('user') == 'pm'){
      this.$axios.get('wts/get/projects').then(response => {
        this.projectData = response.data.result
      })
      .catch(error => {

      })
      this.$axios.get('wts/get/profile').then(response => {
        this.companyid = response.data.companyid
      })
      .catch(error => {

      })
    }
    else {
      this.$axios.get('manager/get/projects').then(response => {
        this.projectData = response.data.result
      })
      .catch(error => {

      })
      this.$axios.get('manager/get/profile').then(response => {
        this.companyid = response.data.companyid
      })
      .catch(error => {

      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .mx-input {
    padding: 20px 15px !important;
  }

  #invoice-chart {
    #chartdiv {
      padding-top: 40px;
    }

    position: relative;
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      width: 100px;
      height: inherit;
      padding: 0.5em;
    }
    .amcharts-amexport-menu-level-0.amcharts-amexport-top {
      top: -40px !important;
    }
    .amcharts-amexport-item {
      border: none;
      background: #4dbd74;

      a {
        color: #fff;
      }
    }

    #loading-gif {
      z-index: 9999;
      position: absolute;
      top: 200px;
      right: 50%;
    }
  }
</style>
