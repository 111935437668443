var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        [
          _c("h4", [
            _c("i", { staticClass: "fa fa-chart-line" }),
            _vm._v(" Invoice Chart")
          ]),
          _c("Chart")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }